<template>
  <div class="arts-container">
    <pageHeader class="page-header"></pageHeader>
    <div class="content-body w1200">
      <div class="page-content">
        <router-view></router-view>
      </div>
    </div>
    <pageFooter class="page-footer"></pageFooter>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { camelToDashLine, createWalletConnect } from '@/utils/func.js'
import pageHeader from '@/components/layout/pageHeader.vue'
import pageFooter from '@/components/layout/pageFooter.vue'

export default {
  name: 'arts',

  components: {
    pageHeader,
    pageFooter
  },

  data () {
    return {}
  },

  // TODO
  beforeRouteEnter (to, from, next) {
    if (to.name === 'arts') {
      // to arts index page
      // this.toPath('ShowCenter', true)
      next('/arts/show-center')
    } else {
      next()
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'reverseFullName']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    }
  },

  created () {
    this.initFunc()
    window.addEventListener('beforeunload', this.beforeunloadHandler)
  },

  beforeDestroy () {
    if (window.walletConnectWeb3Provider) {
      window.walletConnectWeb3Provider.removeAllListeners()
    } else if (window.ethereum) {
      window.ethereum.removeAllListeners()
    }
    window.removeEventListener('beforeunload', this.beforeunloadHandler)
  },

  methods: {
    ...mapActions([
      'setLogin',
      'setAddress',
      'setChainId',
      'setReverseFullName'
    ]),

    toPath (fileName, replace) {
      const path = '/arts/' + camelToDashLine(fileName)

      if (this.$route.path === path) {
        return
      }

      if (replace) {
        this.$router.replace(path)
        return
      }

      this.$router.push(path)
    },

    async beforeunloadHandler (event) {
      if (!window.localStorage.getItem('walletconnect')) {
        try {
          const accounts = await window.ethereum.request({
            method: 'eth_accounts'
          })
          this.setAddress(accounts[0])
        } catch (err) {
          console.err(err)
        }

        if (this.address) {
          window.localStorage.setItem('user', JSON.stringify({
            address: this.address
          }))
        }
      }
    },

    async initFunc () {
      // TODO
      if (window.localStorage.getItem('walletconnect')) {
        const provider = await createWalletConnect()

        if (window.walletConnectWeb3Provider && provider) {
          // Listen to EIP-1193 events to detect accounts and chain change and also disconnection.
          // Subscribe to accounts change
          window.walletConnectWeb3Provider.on('accountsChanged:', (accounts) => {
            console.log('accountsChanged:', accounts)
            this.setAddress(accounts[0])
          })

          // Subscribe to chainId change
          window.walletConnectWeb3Provider.on('chainChanged', (chainId) => {
            console.log('chainChanged:', chainId)
            this.setChainId('0x' + chainId.toString(16))
          })

          // Subscribe to session connection
          window.walletConnectWeb3Provider.on('connect', () => {
            console.log('connect')
          })

          // Subscribe to session disconnection, default cached provider is cleared,
          window.walletConnectWeb3Provider.on('disconnect', (code, reason) => {
            console.log(code, reason)
            delete window.walletConnectWeb3Provider
            window.localStorage.removeItem(this.address)
            this.setLogin(false)
            this.setAddress('')
            window.location.reload()
          })

          try {
            const signer = await provider.getSigner()
            const chainId = '0x' + (await signer.getChainId()).toString(16)
            const address = await signer.getAddress()

            console.log('Current signer:', address, 'chainId:', chainId)

            if (window.localStorage.getItem(address)) {
              const loginInfo = JSON.parse(window.localStorage.getItem(address))
              const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
              if (loginInfo.isLogin && due > Date.now()) {
                this.setLogin(loginInfo.isLogin)
                this.setAddress(address)
                this.setChainId(chainId)
              } else {
                window.localStorage.removeItem(address)
              }
            }
          } catch (err) {
            console.log(err)
          }
        }
      } else if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum.on('accountsChanged', this.handleAccountsChanged)
        window.ethereum.on('chainChanged', this.handleChainChanged)
        window.ethereum.on('disconnect', this.handleAccountsDisconnect)

        try {
          const chainId = await window.ethereum.request({
            method: 'eth_chainId'
          })
          if (chainId !== this.supportChainId) return
        } catch (err) {
          console.log(err)
          return
        }

        let account
        if (window.localStorage.getItem('user')) {
          const user = JSON.parse(window.localStorage.getItem('user'))
          account = user.address
          window.localStorage.removeItem('user')
        }

        if (window.localStorage.getItem(account)) {
          const loginInfo = JSON.parse(window.localStorage.getItem(account))
          const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
          if (loginInfo.isLogin && due > Date.now()) {
            this.setLogin(loginInfo.isLogin)
            this.setAddress(loginInfo.address)
            this.setChainId(loginInfo.chainId)
          } else {
            window.localStorage.removeItem(account)
          }
        }
      }
    },

    handleAccountsDisconnect () {
      console.log('disconnect')
    },

    handleAccountsChanged (accounts) {
      console.log('accountChanged:', accounts)
      if (this.address) {
        this.accountChanged = true
        window.location.reload()
      }
    },

    handleChainChanged (chainId) {
      this.setChainId(chainId)
      // window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>

.arts-container{
  min-height: 100vh;
  overflow: auto;
  background: linear-gradient(#e9eaff,#fbfbff);
}

.content-body{
  padding-top: 100px;
  min-height: 100vh;
}

.page-content{
  padding: 60px 0 120px;
}
@media screen  and (max-width: $mobileWidth){
  .page-content{
    padding-top: 12px;
  }
}
</style>
